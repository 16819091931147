import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from "@mui/material"
import { Link, Navigate, Outlet } from "react-router-dom"
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingBackdrop from "../LoadingBackdrop";
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import { useLogoutMutation, useUsersMeQuery } from "../../../features/auth/hooks/user";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';


const StyledDrawer = styled(Drawer)({
    width: '250px',
    '& .MuiDrawer-paper': {
        width: '250px',
        color: 'white',
        backgroundColor: '#262626'
    }
})

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'black',
})

const StyledBox = styled(Box)({
    width: '100%',
    backgroundColor: '#F5F6FA'
})

const StyledListItemIcon = styled(ListItemIcon)({
    color: 'white'
})

const StyledListItemText = styled(ListItemText)({
    color: 'white'
})


const navbarItems = [
    { text: "Start", path: "", icon: <HomeIcon /> },
    { text: "Opiekunki", path: "caregivers/", icon: <PeopleIcon /> },
]


export default () => {
    const userQuery = useUsersMeQuery();
    const logoutMutation = useLogoutMutation()

    if (userQuery.isError || logoutMutation.isSuccess) return <Navigate to="/" />

    if (userQuery.isLoading || logoutMutation.isLoading) return <LoadingBackdrop />

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100vh' }}>
            <StyledDrawer variant="permanent">
                <Box sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Box>
                        <Typography sx={{ marginY: 2 }} variant="h4" align="center">BestHelfen</Typography>
                        <Divider />
                        <List>
                            {navbarItems.map((item) => (
                                <ListItem key={item.text} component={StyledLink} to={`/dashboard/${item.path}`}>
                                    <ListItemButton>
                                        <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                                        <StyledListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemButton onClick={() => logoutMutation.mutate()}>
                                    <StyledListItemIcon>
                                        <LogoutIcon />
                                    </StyledListItemIcon>
                                    <StyledListItemText primary="Wyloguj się" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </StyledDrawer>
            <StyledBox>
                <Outlet />
            </StyledBox>
            <ToastContainer />
        </Box>
    )

    return null;
}


