import axios from "../../../utils/axios"
import { QuestionAnswerPayload } from "../components/CaregiverQuestionsList";
import { Filters } from "../components/CaregiversListPage";
import { CaregiverCreate } from "../components/FilterBar";
import { Caregiver, CaregiverDetail, CaregiverExperiences, CaregiverReference, CaregiverReferencePayload, CaregiverUpdate, Experience } from "../types/caregiver"

export const fetchCaregivers = async (filters: Filters): Promise<Caregiver[]> => {
    const transformedFilters = {
        q: filters.q || null,
        minAge: filters.minAge || null,
        maxAge: filters.maxAge || null,
        hasDrivingLicense: filters.hasDrivingLicense || null,
        isSmoker: filters.isSmoker || null,
        availabilityStartDate: filters.availabilityStartDate || null,
        availabilityEndDate: filters.availabilityEndDate || null,
    }
    const response = await axios.get("/caregivers/", { params: transformedFilters });
    return response.data;
}

export const fetchCaregiver = async (caregiverId: number): Promise<CaregiverDetail> => {
    const response = await axios.get(`/caregivers/${caregiverId}/`);
    return response.data;
}

export const createCaregiver = async (data: CaregiverCreate): Promise<void> => {
    await axios.post("/caregivers/", data);
}

export const updateCaregiver = async (caregiverId: number, data: CaregiverUpdate): Promise<void> => {
    await axios.put(`/caregivers/${caregiverId}/`, data);
}

export const updateCaregiverPhoto = async (caregiverId: number, data: { file: File }): Promise<void> => {
    const formData = new FormData()
    formData.append("file", data.file)
    await axios.put(`/caregivers/${caregiverId}/photo/`, formData, { headers: { "Content-Type": "multipart/form-data"}})
}

export const updateCaregiverQuestions = async (caregiverId: number, data: QuestionAnswerPayload[]): Promise<void> => {
    await axios.put(`/caregivers/${caregiverId}/questions/`, data);
}

export const fetchCaregiverExperiences = async (caregiverId: number): Promise<CaregiverExperiences> => {
    const response = await axios.get(`/caregivers/${caregiverId}/experiences/`);
    return response.data
}

export const deleteCaregiverExperience = async (caregiverId: number, experienceId: number): Promise<void> => {
    await axios.delete(`/caregivers/${caregiverId}/experiences/${experienceId}/`);
}

export const updateCaregiverExperience = async (caregiverId: number, experienceId: number, data: Experience): Promise<void> => {
    await axios.put(`/caregivers/${caregiverId}/experiences/${experienceId}/`, data);
}

export const createCaregiverExperience = async (caregiverId: number, data: Experience): Promise<void> => {
    await axios.post(`/caregivers/${caregiverId}/experiences/`, data);
}

export const fetchCaregiverReferences = async (caregiverId: number): Promise<CaregiverReference[]> => {
    const response = await axios.get(`/caregivers/${caregiverId}/references/`);
    return response.data
}

export const createCaregiverReference = async (caregiverId: number, data: CaregiverReferencePayload): Promise<void> => {
    const formData = new FormData()
    formData.append("file", data.file[0])

    await axios.post(`/caregivers/${caregiverId}/references/?name=${data.name}&reference_type=${data.referenceType}`, formData, { headers: { "Content-Type": "multipart/form-data"}})
}

export const deleteCaregiverReferenceMutation = async (caregiverId: number, referenceId: number): Promise<void> => {
    await axios.delete(`/caregivers/${caregiverId}/references/${referenceId}/`)
}

export const generateProfile = async (caregiverId: number): Promise<Blob> => {
    const response = await axios.get(`/caregivers/${caregiverId}/profile/`, { responseType: 'blob' })
    return response.data
}