import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { CssBaseline, ThemeProvider } from '@mui/material';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      staleTime: 20000,
      retry: false,
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
      <CssBaseline />
        <App/>
      </QueryClientProvider>
  </React.StrictMode>
);
