import axios from "axios"
import { refreshAccessToken } from "../features/auth/api"

const authAxios = axios.create({
  baseURL: "https://api.panel.besthelfen.pl",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
})

authAxios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      await refreshAccessToken()
    }
    return Promise.reject(error)
  }
)

export default authAxios
