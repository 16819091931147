import { FilterSharp, SettingsApplicationsRounded } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCaregiverCreateMutation } from "../hooks/caregiver";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../../common/components/Inputs/ControlledTextField";
import { Filters } from "./CaregiversListPage";
import { DatePicker } from "@mui/x-date-pickers";

const phoneRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;


export interface CaregiverCreate {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
}


const caregiverCreateSchema = yup.object({
    firstName: yup.string().min(3, "Podane imię jest za krótkie.").max(100).required("Podaj imię."),
    lastName: yup.string().min(3, "Podane nazwisko jest za krótkie.").max(100).required("Podaj nazwisko."),
    email: yup.string().email("Podany email jest niepoprawny.").required("Podaj email."),
    phoneNumber: yup.string().min(6, "Podany numer telefonu jest za krótki.").required("Podaj numer telefonu.")
})

export const CaregiverCreateForm = ({ isOpened, setIsOpened }: { isOpened: boolean, setIsOpened: (open: boolean) => void }) => {
    const methods = useForm<CaregiverCreate>({ resolver: yupResolver(caregiverCreateSchema), mode: 'onChange' });
    const { mutate } = useCaregiverCreateMutation()

    return (
        <Dialog open={isOpened}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data) => mutate({ data }))}>
                    <DialogTitle id="edit-apartment">Nowa opiekunka</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Podaj podstawowe informacje, aby dodać opiekunkę do bazy opiekunek.
                        </DialogContentText>
                        <ControlledTextField name="firstName" label="Imie" />
                        <ControlledTextField name="lastName" label="Nazwisko" />
                        <ControlledTextField name="email" label="Email" />
                        <ControlledTextField name="phoneNumber" label="Numer telefonu" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpened(false)} variant="contained" color="error">
                            Anuluj
                        </Button>
                        <Button type="submit" disabled={!methods.formState.isValid} onClick={() => setIsOpened(false)} variant="contained">
                            Dodaj
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    )
}

export const FilterBar = ({ query, setQuery }: { query: Filters, setQuery: React.Dispatch<React.SetStateAction<Filters>>}) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const fu = (filters: Filters, q: string): Filters => {
        return {...filters, q}
    }
    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%', backgroundColor: '#FFFFFF', paddingX: 3, paddingY: 1, borderRadius: 2, marginY: 3 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
                <TextField label="Szukaj" value={query.q} onChange={(e) => setQuery(filters => { return {...filters, q: e.target.value}})} size="small" variant="outlined" />
                <TextField sx={{ width: '100px' }}label="Wiek od" value={query.minAge} onChange={(e) => setQuery(filters => { return {...filters, minAge: e.target.value}})} size="small" type="number" variant="outlined" />
                <TextField sx={{ width: '100px' }}label="Wiek do" value={query.maxAge} onChange={(e) => setQuery(filters => { return {...filters, maxAge: e.target.value}})} size="small" type="number" variant="outlined" />
                {/* <DatePicker sx={{ width: '170px'}} label="Dostępność od" slotProps={{ textField: { size: "small" }}} value={query.availabilityStartDate} onChange={(value) => setQuery(filters => { return {...filters, availabilityStartDate: value }})} views={["year", "month", "day"]} openTo={"year"} /> */}

            </Box>
            <Box>
                <Button onClick={() => setIsOpened(true)} variant="contained">Dodaj</Button>
                <CaregiverCreateForm isOpened={isOpened} setIsOpened={setIsOpened} />
            </Box>
        </Box>
    )
}