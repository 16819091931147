import { AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material"
import { useCaregiverExperienceCreateMutation, useCaregiverExperiencesDeleteMutation, useCaregiverExperiencesQuery, useCaregiverExperiencesUpdateMutation } from "../hooks/caregiver"
import { FormProvider, useForm } from "react-hook-form"
import { useState } from "react"
import { Experience, ExperienceTransformed, Trait } from "../types/caregiver"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlledTextField from "../../../common/components/Inputs/ControlledTextField"
import ControlledDatePicker from "../../../common/components/Inputs/ControlledDatePicker"
import { formatToAPI } from "../../../utils/dates"
import ControlledSelect from "../../../common/components/Inputs/ControlledSelect"
import { nationalities } from "./CaregiverData"
import ControlledCheckbox from "../../../common/components/Inputs/ControlledCheckbox"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"

const transformData = ({ startDate, endDate, ...data }: Experience): Experience => {
    return { ...data, startDate: formatToAPI(startDate), endDate: formatToAPI(endDate) }
}

export const genders = [{label: "Mężczyzna", value: "M"}, {label: "Kobieta", value: "F"}]


const validationSchema = yup.object({
    firstName: yup.string().min(3, "Imię nie może być krótsze niż 3 znaki").required("Podaj imię pacjenta"),
    age: yup.number().typeError("Podaj wiek pacjenta").positive("Wiek nie może być ujemny").required("Podaj wiek pacjenta"),
    startDate: yup.date(),
    endDate: yup.date(),
})

const DialogForm = ({ availableTraits, experience, caregiverId, isOpened, setIsOpened }: { availableTraits: Trait[], experience: Experience, caregiverId: number, isOpened: boolean, setIsOpened: (isOpened: boolean) => void }) => {
    const methods = useForm<Experience>({ defaultValues: experience, resolver: yupResolver(validationSchema)})
    const { mutate } = useCaregiverExperiencesUpdateMutation()

    const activities: Trait[] = availableTraits.filter(trait => trait.traitType === "ACTIVITY")
    const diseases: Trait[]  = availableTraits.filter(trait => trait.traitType === "DISEASE")

    return (
        <Dialog open={isOpened} fullScreen sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "50%",
              },
            },
          }}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(data => mutate({ caregiverId, experienceId: experience.id, data: transformData(data) }))}>
                    <DialogTitle>Aktualizacja doświadczenia</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Zaktualizuj informacje i zapisz doświadczenie.
                        </DialogContentText>
                        <ControlledTextField name="firstName" label="Imie" />
                        <ControlledSelect name="country" label="Kraj" options={nationalities} />
                        <ControlledSelect name="gender" label="Płeć" options={genders} />
                        <ControlledTextField name="age" label="Wiek" type="number"/>
                        <ControlledDatePicker name="startDate" label="Data rozpoczęcia" />
                        <ControlledDatePicker name="endDate" label="Data zakończenia" />
                        <ControlledCheckbox name="inCare" label="Praca w opiece" />

                        <Typography variant="h6">Aktywności</Typography>
                        {activities.map(trait =>
                            <FormControlLabel
                                key={trait.id}
                                value={trait.id}
                                control={<Checkbox size="small" defaultChecked={experience.selectedTraits.includes(trait.id)} />}
                                label={trait.name}
                                {...methods.register(`selectedTraits`)}
                            />)}
                        <Typography variant="h6">Choroby</Typography>
                        {diseases.map(trait =>
                            <FormControlLabel
                                key={trait.id}
                                value={trait.id}
                                control={<Checkbox size="small" defaultChecked={experience.selectedTraits.includes(trait.id)} />}
                                label={trait.name}
                                {...methods.register(`selectedTraits`)}
                            />)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpened(false)} variant="contained" color="error">
                            Anuluj
                        </Button>
                        <Button type="submit" disabled={!methods.formState.isValid} onClick={() => setIsOpened(false)} variant="contained">
                            Zapisz
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    )
}

const DialogFormCreate = ({ availableTraits, caregiverId, isOpened, setIsOpened }: { availableTraits: Trait[], caregiverId: number, isOpened: boolean, setIsOpened: (isOpened: boolean) => void }) => {
    const methods = useForm<Experience>({ defaultValues: { inCare: true, gender: "M", country: "PL"}, resolver: yupResolver(validationSchema), mode: "onChange"})
    const { mutate } = useCaregiverExperienceCreateMutation()

    const activities: Trait[] = availableTraits.filter(trait => trait.traitType === "ACTIVITY")
    const diseases: Trait[]  = availableTraits.filter(trait => trait.traitType === "DISEASE")
    
    return (
        <Dialog open={isOpened} onClose={() => setIsOpened(false)} sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "50%",
              },
            },
          }}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(data => mutate({ caregiverId, data: transformData(data) }))}>
                    <DialogTitle>Nowe doświadczenie</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ marginBottom: 2 }}>
                            Podaj podstawowe informacje, aby dodać nowe doświadczenie.
                        </DialogContentText>
                        <ControlledTextField name="firstName" label="Imię pacjenta" />
                        <ControlledSelect name="country" label="Kraj" options={nationalities} /> 
                        <Grid container columnSpacing={2}>
                            <Grid sm={12} md={8} item><ControlledSelect name="gender" label="Płeć" options={genders}/></Grid>
                            <Grid sm={12} md={4} item><ControlledTextField name="age" label="Wiek" type="number" /></Grid>
                        </Grid>
                        <Grid container columnSpacing={2}>
                            <Grid sm={12} md={6} item><ControlledDatePicker name="startDate" label="Data rozpoczęcia" /></Grid>
                            <Grid sm={12} md={6} item><ControlledDatePicker name="endDate" label="Data zakończenia" /></Grid>
                        </Grid>
                        <ControlledCheckbox name="inCare" label="Praca w opiece"/>
                        <Typography variant="h6">Aktywności</Typography>
                        {activities.map(trait =>
                            <FormControlLabel
                                key={trait.id}
                                value={trait.id}
                                control={<Checkbox size="small" defaultChecked={false} />}
                                label={trait.name}
                                {...methods.register(`selectedTraits`)}
                            />)}
                        <Typography variant="h6">Choroby</Typography>
                        {diseases.map(trait =>
                            <FormControlLabel
                                key={trait.id}
                                value={trait.id}
                                control={<Checkbox size="small" defaultChecked={false} />}
                                label={trait.name}
                                {...methods.register(`selectedTraits`)}
                            />)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpened(false)} variant="contained" color="error">
                            Anuluj
                        </Button>
                        <Button type="submit" disabled={!methods.formState.isValid} onClick={() => setIsOpened(false)} variant="contained">
                            Zapisz
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    )
}

const ExperienceItem = ({ experience, availableTraits, caregiverId }: { experience: ExperienceTransformed, availableTraits: Trait[], caregiverId: number }) => {
    const { mutate } = useCaregiverExperiencesDeleteMutation()
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState<boolean>(false);

    return (
        <Box sx={{ width: '100%' }} key={experience.id}>
            <Box sx={{ alignItems: "center", justifyContent: "space-between", display: "flex" }}>
                <Box>
                <Typography variant="h6">
                    {experience.firstName} ({genders.find(gender => gender.value === experience.gender)?.label}, {nationalities.find(nationality => nationality.value === experience.country)?.label})
                </Typography>
                <Typography sx={{ marginBottom: 2 }} variant="subtitle2">od {experience.startDate} do {experience.endDate}</Typography>
                </Box>
                <Box>
                    <IconButton color="primary" size="small" onClick={() => setIsOpened(true)}>
                        <EditIcon />
                    </IconButton>
                    <ConfirmDialog open={isConfirmDialogOpened} onCancel={() => setIsConfirmDialogOpened(false)} onConfirm={() => mutate({ caregiverId, experienceId: experience.id })}/>
                    <IconButton color="error" size="small" onClick={() => setIsConfirmDialogOpened(true)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>
            <Typography>
                Choroby pacjenta: {experience.diseases.length > 0 ? experience.diseases.map(disease => disease.name).join(", ") : "Brak"}
            </Typography>
            <Typography>
                Aktywności: {experience.activities.length > 0 ? experience.activities.map(activity => activity.name).join(", ") : "Brak"}
            </Typography>
            <DialogForm experience={experience} caregiverId={caregiverId} isOpened={isOpened} setIsOpened={setIsOpened} availableTraits={availableTraits} />
        </Box>
    )
}

const ConfirmDialog = ({ open, onCancel, onConfirm }: { open: boolean, onCancel: () => void, onConfirm: () => void}) => {
    return (
        <Dialog
        open={open}
        onClose={onCancel}
      >
        <DialogTitle>
          Potwierdź usunięcie doświadczenia
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć wybrane doświadczenie?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancel}>Anuluj</Button>
          <Button variant="contained" color="error" onClick={onConfirm} autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export const CaregiverExperiences = ({ caregiverId }: { caregiverId: number }) => {
    const { data, isLoading, isIdle, isError } = useCaregiverExperiencesQuery(caregiverId)
    const [isOpened, setIsOpened] = useState<boolean>(false)

    if (isIdle || isError) return <>Error</>

    if (isLoading) return <>Loading...</>

    return (
        <>
            <DialogFormCreate caregiverId={caregiverId} isOpened={isOpened} setIsOpened={setIsOpened} availableTraits={data.availableTraits} />
            <Stack sx={{ width: '100%' }} spacing={2} divider={<Divider flexItem />}>
                {data.experiences.length > 0 ?
                data.experiences.map(experience => (
                    <ExperienceItem key={experience.id} experience={experience} availableTraits={data.availableTraits} caregiverId={caregiverId} />
                    )) : "Nie dodano żadnego doświadczenia."}
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button sx={{ marginTop: 4 }} disableElevation variant="contained" onClick={() => setIsOpened(true)} color="primary">
                    Dodaj
                </Button>
            </Box>
        </>
    )
}