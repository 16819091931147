import { Box, Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TableRowProps, TableSortLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { dateToUTC, timeFromNowAgo, timeFromNow, toDayjs } from "../../../utils/dates"
import { Caregiver } from "../types/caregiver"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface CaregiverListProps {
    caregivers: Caregiver[]
}

const StyledTableRow = styled(TableRow)({
    '&:last-child td, &:last-child th': {
        border: 0
    },
    "&.MuiTableRow-hover": {
        '&:hover': {
            cursor: "pointer"
        }
    }
})

export const CaregiverList = ({ caregivers }: CaregiverListProps) => {
    const navigate = useNavigate()

    return (
        <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Imię</TableCell>
                        <TableCell align="center">Nazwisko</TableCell>
                        <TableCell align="center">Wiek</TableCell>
                        <TableCell align="center">Prawo jazdy</TableCell>
                        <TableCell align="center">Osoba paląca</TableCell>
                        <TableCell align="center">Dostępność od</TableCell>
                        <TableCell align="center">Dostępność do</TableCell>
                        <TableCell align="center">Dodano</TableCell>
                        <TableCell align="center">Ostatnia aktualizacja</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {caregivers.map(caregiver => (
                        <StyledTableRow
                            key={caregiver.id}
                            hover
                            onClick={() => navigate(`/dashboard/caregivers/${caregiver.id}/`)}
                        >
                            <TableCell align="center">{caregiver.firstName}</TableCell>
                            <TableCell align="center">{caregiver.lastName}</TableCell>
                            <TableCell align="center">{caregiver.age || "-"}</TableCell>
                            <TableCell align="center">{caregiver.hasDrivingLicense ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small"/>}</TableCell>
                            <TableCell align="center">{caregiver.isSmoker ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small"/>}</TableCell>
                            <TableCell align="center">{caregiver.availabilityStartDate || "-"}</TableCell>
                            <TableCell align="center">{caregiver.availabilityEndDate || "-"}</TableCell>
                            <TableCell align="center">{timeFromNowAgo(caregiver.createdAt)}</TableCell>
                            <TableCell align="center">{timeFromNowAgo(caregiver.updatedAt)}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}