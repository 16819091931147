import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import LoadingBackdrop from "../../../common/components/LoadingBackdrop";
import { generateProfile } from "../api";
import { useCaregiverDetailQuery } from "../hooks/caregiver";
import { CaregiverData } from "./CaregiverData";
import { CaregiverExperiences } from "./CaregiverExperiences";
import { CaregiverReferences } from "./CaregiverReferences";


export const CaregiverDetail = () => {
    const { caregiverId } = useParams() as unknown as { caregiverId: number };
    const { isError, isIdle, isLoading, data } = useCaregiverDetailQuery(caregiverId)
    const [tab, setTab] = useState<string>("1")

    if (isError || isIdle) return <>Nie znaleziono opiekunki!</>

    if (isLoading) return <LoadingBackdrop />

    const generatePDF = async () => {
        const blob = await generateProfile(data.id);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a")
        link.href = url
        link.download = `${data.firstName}_${data.lastName}.pdf`
        document.body.appendChild(link);
        link.click();
        link.remove();
    }


    return (
        <Box sx={{ width: '95%', marginX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Paper elevation={0} sx={{ width: '100%', paddingX: 2, paddingY: 3, marginTop: 2 }}>
                <Box sx={{ marginBottom: 4, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h4" component="h1">{data.firstName} {data.lastName}</Typography>
                    <Button variant="contained" onClick={async () => generatePDF()}>Wygeneruj profil</Button>
                </Box>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(_, tab) => setTab(tab)} aria-label="basic tabs example">
                            <Tab label="Dane" value="1" />
                            <Tab label="Doświadczenie" value="2" />
                            <Tab label="Załączniki" value="3" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <CaregiverData caregiver={data} />
                    </TabPanel>
                    <TabPanel value="2">
                        <CaregiverExperiences caregiverId={data.id} />
                    </TabPanel>
                    <TabPanel value="3">
                        <CaregiverReferences caregiverId={data.id} />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Box>
    )
}