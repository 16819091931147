import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useCaregiverUpdateMutation, useCaregiverUpdatePhotoMutation } from "../hooks/caregiver";
import { CaregiverDetail, CaregiverUpdate, Language } from "../types/caregiver";
import * as yup from "yup";
import { Box, Button, Divider, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingBackdrop from "../../../common/components/LoadingBackdrop";
import { formatToAPI, toDayjs } from "../../../utils/dates";
import ControlledTextField from "../../../common/components/Inputs/ControlledTextField";
import ControlledDatePicker from "../../../common/components/Inputs/ControlledDatePicker";
import ControlledSelect from "../../../common/components/Inputs/ControlledSelect";
import { genders } from "./CaregiverExperiences";
import ControlledCheckbox from "../../../common/components/Inputs/ControlledCheckbox";
import avatar from '../../../assets/avatar.svg'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { toast } from "react-toastify";

const PhotoUploadForm = ({ caregiver }: { caregiver: CaregiverDetail }) => {
    const { mutate, isLoading } = useCaregiverUpdatePhotoMutation()
    const [photo, setPhoto] = useState<File | null>(null);

    useEffect(() => {
        if (photo) {
            if (photo.size < 2e6) {
                mutate({ caregiverId: caregiver.id, data: { file: photo } })
            } else {
                toast.error("Rozmiar zdjęcia profilowego musi być mniejszy niż 2MB", { autoClose: 3000 })
            }
            setPhoto(null);
        }
    }, [photo])

    return (
        <>
            <Box sx={{ height: 'auto', width: '300px' }} component="img" src={caregiver.photoPath ? caregiver.photoPath : avatar}></Box>
            <Box>
                <Button sx={{ marginTop: 4 }} component="label" variant="contained">
                    Wybierz zdjęcie
                    <input type="file" hidden accept=".jpg,.jpeg,.png,.gif" onChange={e => {
                        if (e.target.files) {
                            setPhoto(e.target.files[0])
                        }
                    }} />
                </Button>
            </Box>
            {isLoading && <LoadingBackdrop />}
        </>
    )
}


export const nationalities = [
    { label: "Polska", value: "PL" },
    { label: "Ukraina", value: "UA" },
    { label: "Rumunia", value: "RO" },
    { label: "Niemcy", value: "DE" },
    { label: "Białoruś", value: "BY" },
    { label: "Mołdawia", value: "MD" },
    { label: "Grecja", value: "GR" },
    { label: "Włochy", value: "IT" },
    { label: "Szwajcaria", value: "CH" },
    { label: "Rosja", value: "RU" },
]

export const languages = [
    { label: "Polski", value: "PL" },
    { label: "Angielski", value: "GB" },
    { label: "Ukraiński", value: "UA" },
    { label: "Rumuński", value: "RO" },
    { label: "Białoruski", value: "BY" },
    { label: "Mołdawski", value: "MD" },
    { label: "Grecki", value: "GR" },
    { label: "Włoski", value: "IT" },
    { label: "Rosyjski", value: "RU" },
]

const educations = [
    { label: "Podstawowe", value: "PRIMARY" },
    { label: "Średnie", value: "SECONDARY" },
    { label: "Wyższe", value: "HIGHER" },
    { label: "Zawodowe", value: "VOCATIONAL" },
]

const languageLevels = [
    { label: "Płynny", value: "FLUENT" },
    { label: "Tworzenie zdań", value: "SENTENCES" },
    { label: "Duże słownictwo", value: "BIG_VOCABULARY" },
    { label: "Małe słownictwo", value: "SMALL_VOCABULARY" },
    { label: "Niewiele słów", value: "FEW_WORDS" },
    { label: "Brak znajomości", value: "NO_LANGUAGE" },
]

const languageLearningSources = [
    { label: "Szkoła", value: "SCHOOL" },
    { label: "Telewizja", value: "TELEVISION" },
    { label: "Kurs w Polsce", value: "COURSE_IN_POLAND" },
    { label: "Kurs w Niemczech", value: "COURSE_IN_CONTRY" },
    { label: "Samouk", value: "SELF_LEARNT" },
    { label: "Praca", value: "WORK" },
]

const LanguageItem = ({ index, remove }: { index: number, remove: (index: number) => void }) => {
    return <>
        <Grid item sm={5}><ControlledSelect name={`languages.${index}.language`} label="Język" options={languages} /></Grid>
        <Grid item sm={5}><ControlledSelect name={`languages.${index}.languageLevel`} label="Poziom języka" options={languageLevels} /></Grid>
        <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}><IconButton color="error" onClick={() => remove(index)}><RemoveCircleIcon /></IconButton></Grid>
    </>
}

interface Option {
    label: string
    value: string
}


const CaregiverPersonalInfo = () => {
    return (
        <>
            <Typography variant="h5">Dane Osobowe</Typography>
            <Grid container columnSpacing={1}>
                <Grid item sm={12} md={6}>
                    <ControlledTextField disabled name="firstName" label="Imię" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <ControlledTextField disabled name="lastName" label="Nazwisko" />
                </Grid>

                <Grid item sm={12} lg={4}><ControlledDatePicker name="dateOfBirth" label="Data urodzenia" /></Grid>
                <Grid item sm={12} lg={4}><ControlledSelect name="gender" label="Płeć" options={genders} /></Grid>
                <Grid item sm={12} lg={2}><ControlledTextField name="height" label="Wzrost" type="number" /></Grid>
                <Grid item sm={12} lg={2}><ControlledTextField name="weight" label="Waga" type="number" /></Grid>
                <Grid item sm={12} lg={6}><ControlledTextField name="identificationNumber" label="PESEL" /></Grid>
                <Grid item sm={12} lg={6}><ControlledSelect name="nationality" label="Kraj" options={nationalities} /></Grid>
            </Grid>
        </>
    )
}

const CaregiverAdditionalInfo = ({ availableJobs, availableHobbies }: { availableJobs: Option[], availableHobbies: Option[] }) => {
    return (
        <>
            <Typography variant="h5">Dodatkowe informacje</Typography>
            <Grid container columnSpacing={1}>
                <Grid item sm={12}>
                    <ControlledSelect name="education" label="Wykształcenie" options={educations} />
                </Grid>
                <Grid item sm={12} lg={9}><ControlledSelect name="jobId" label="Zawód" options={availableJobs} /></Grid>
                <Grid item sm={12} lg={3}><ControlledTextField name="seniority" label="Staż pracy" type="number" /></Grid>
                <Grid item sm={12} lg={6}><ControlledDatePicker name="availabilityStartDate" label="Dostępność od" /></Grid>
                <Grid item sm={12} lg={6}><ControlledDatePicker name="availabilityEndDate" label="Dostępność do" /></Grid>
                <Grid item sm={12}><ControlledSelect multiple name="hobbies" label="Hobby i zainteresowania" options={availableHobbies} /></Grid>
                <Grid item sm={12}>
                    <ControlledCheckbox name="hasDrivingLicense" label="Prawo jazdy" />
                    <ControlledCheckbox name="isSmoker" label="Osoba paląca" />
                </Grid>
            </Grid>
        </>
    )
}

const CaregiverLanguages = () => {
    const { control } = useFormContext()
    const { append, remove, fields } = useFieldArray({ control, name: "languages" })

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: 3 }}>Języki</Typography>
            <Grid container columnSpacing={1}>
                <Grid item sm={12} lg={6}><ControlledSelect name="germanLevel" label="Poziom niemieckiego" options={languageLevels} /></Grid>
                <Grid item sm={12} lg={6}><ControlledSelect name="germanLearningSource" label="Miejsce nauki niemieckiego" options={languageLearningSources} /></Grid>

                {fields.map((_, index) => {
                    return <LanguageItem key={index} index={index} remove={remove} />
                })}
                <IconButton
                    color="success"
                    sx={{ marginLeft: 1, marginTop: 2 }}
                    onClick={() => append({ language: "PL", languageLevel: "FLUENT" })}
                >
                    <AddCircleIcon />
                </IconButton>
            </Grid>
        </>
    )
}

const CaregiverContactInfo = () => {
    return (
        <>
            <Typography variant="h5" sx={{ marginTop: 3 }}>Dane Kontakowe</Typography>
            <Grid container columnSpacing={1}>
                <Grid item sm={12} md={4}>
                    <ControlledTextField name="zipCode" label="Kod pocztowy" />
                </Grid>
                <Grid item sm={12} md={8}>
                    <ControlledTextField name="city" label="Miejscowość" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <ControlledTextField name="street" label="Ulica" />
                </Grid>
                <Grid item sm={12} md={3}>
                    <ControlledTextField name="streetNumber" label="Numer domu" type="number" />
                </Grid>
                <Grid item sm={12} md={3}>
                    <ControlledTextField name="apartamentNumber" label="Numer lokalu" type="number" />
                </Grid>
                <Grid item sm={12}><ControlledTextField name="email" label="Email" /></Grid>
                <Grid item sm={12} lg={6}><ControlledTextField name="phoneNumber" label="Telefon komórkowy" /></Grid>
                <Grid item sm={12} lg={6}><ControlledTextField name="contactPhoneNumber" label="Tel. osoby kontakowej" /></Grid>
            </Grid>
        </>
    )
}

const CaregiverPhoto = ({ caregiver }: { caregiver: CaregiverDetail }) => {
    return (
        <>
            <Typography sx={{ marginTop: 3, marginBottom: 2 }} variant="h5">Zdjęcie profilowe</Typography>
            <Typography variant="subtitle2">Maksymalnie 2MB (.jpg,.jpeg,.png,itd.)</Typography>
            <PhotoUploadForm caregiver={caregiver} />
        </>
    )
}

export const CaregiverData = ({ caregiver }: { caregiver: CaregiverDetail }) => {
    const availableHobbies = caregiver.hobbies.map(hobby => {
        return { label: hobby.name, value: hobby.id }
    })
    const methods = useForm<CaregiverUpdate>({
        defaultValues: {
            ...caregiver, hobbies: caregiver.hobbies.filter(hobby => hobby.selected).map(hobby => hobby.id), jobId: caregiver.jobId ? caregiver.jobId : ""
        },
    });
    const { mutate } = useCaregiverUpdateMutation()

    const transformData = ({ dateOfBirth, availabilityStartDate, availabilityEndDate, jobId, ...data }: CaregiverUpdate): CaregiverUpdate => {
        return {
            ...data,
            dateOfBirth: dateOfBirth ? formatToAPI(dateOfBirth) : null,
            availabilityStartDate: availabilityStartDate ? formatToAPI(availabilityStartDate) : null,
            availabilityEndDate: availabilityEndDate ? formatToAPI(availabilityEndDate) : null,
            jobId: jobId ? jobId : null,
        }
    }

    const submit = (data: CaregiverUpdate) => {
        mutate({ caregiverId: caregiver.id, data: transformData(data) })
        methods.reset(data)
    }

    const availableJobs = caregiver.availableJobs.map(job => {
        return { label: job.name, value: job.id }
    })

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data) => submit(data))}>
                    <Grid container rowSpacing={{ sm: 4 }} columnSpacing={{ md: 3, lg: 6, xl: 12 }}>
                        <Grid item sm={12} lg={7}>
                            <CaregiverPersonalInfo />
                            <CaregiverContactInfo />
                            <CaregiverLanguages />
                        </Grid>
                        <Grid item sm={12} lg={5}>
                            <CaregiverAdditionalInfo availableJobs={availableJobs} availableHobbies={availableHobbies} />
                            <CaregiverPhoto caregiver={caregiver} />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: 3 }} />
                    <Button disabled={!methods.formState.isValid || !methods.formState.isDirty} sx={{ marginTop: 1 }} type="submit" variant="contained">Zapisz</Button>
                </form>

            </FormProvider>
        </>
    )
}