import { Backdrop, Box, Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import LoadingBackdrop from "../../../common/components/LoadingBackdrop";
import { generateProfile } from "../../caregivers/api";
import { useLoginMutation, useUsersMeQuery } from "../hooks/user";


const LoginPage = () => {
    const loginMutation = useLoginMutation()
    const userQuery = useUsersMeQuery()
    const { handleSubmit, register, reset } = useForm<{ username: string, password: string}>()
    
    if (loginMutation.isLoading) return <LoadingBackdrop />
    
    if (loginMutation.isSuccess || userQuery.isSuccess) return <Navigate to="/dashboard/"/>

    return (
        <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper elevation={4} sx={{ width: '50%', maxWidth: '450px', paddingX: '40px', paddingY: '20px', backgroundColor: '#FDFDFD' }}>
                <Typography sx={{ marginBottom: '20px' }} variant="h5" textAlign="center">Witamy w panelu BestHelfen!</Typography>
                <Box>  
                    <form onSubmit={handleSubmit(data => loginMutation.mutate(data))}>
                        <TextField fullWidth label="Nazwa użytkownika" variant="outlined" {...register("username")} />
                        <TextField sx={{ marginTop: 2 }} fullWidth label="Hasło" type="password" variant="outlined" {...register("password")} />
                        {loginMutation.isError && <Typography color="error">Niepoprawne dane logowania.</Typography>}
                        <Button type="submit" variant="contained" sx={{ float: 'right', marginTop: 2}}>Zaloguj</Button>
                    </form>
                </Box>
            </Paper>
        </Box>
    )
}

export default LoginPage;