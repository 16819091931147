import { Box, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react";
import { useCaregiversListQuery } from "../hooks/caregiver"
import { CaregiverList } from "./CaregiverList";
import { FilterBar } from "./FilterBar";


const useLocalStorageState = <T = Record<string, string | boolean>,>({ key, defaultValue }: { key: string, defaultValue: T }): [value: T, setValue: React.Dispatch<React.SetStateAction<T>>] => {
  const item = localStorage.getItem(key)
  const [value, setValue] = useState<T>(item ? JSON.parse(item) : defaultValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value])

  return [value, setValue]
}

const useDebounce = <T,>(value: T, milliSeconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
}

export interface Filters {
  q: string
  hasDrivingLicense: boolean | null
  isSmoker: boolean | null
  minAge: string | null
  maxAge: string | null
  experienceInGermany: string | null
  availabilityStartDate: string | null
  availabilityEndDate: string | null
}

const defaultFilters = {
  q: "",
  hasDrivingLicense: null,
  isSmoker: null,
  minAge: "",
  maxAge: "",
  experienceInGermany: null,
  availabilityStartDate: null,
  availabilityEndDate: null,
}

export const CaregiverListPage = () => {
  const [query, setQuery] = useLocalStorageState<Filters>({ key: "filters", defaultValue: defaultFilters })
  const debouncedQuery = useDebounce(query, 250);
  const { isIdle, isError, isLoading, data } = useCaregiversListQuery(debouncedQuery)

  if (isError || isIdle) return <>Something went wrong.</>

  return (
    <Box sx={{ width: '95%', marginX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FilterBar query={query} setQuery={setQuery} />
      {isLoading ? <CircularProgress />
        : <CaregiverList caregivers={data} />
      }
    </Box>
  )
}